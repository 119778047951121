<!--&lt;!&ndash; Navigation &ndash;&gt;-->
<!--<treo-vertical-navigation class="theme-dark"-->
<!--                          [appearance]="'classic'"-->
<!--                          [mode]="isScreenSmall ? 'over' : 'side'"-->
<!--                          [name]="'mainNavigation'"-->
<!--                          [navigation]="data.navigation.default"-->
<!--                          [opened]="!isScreenSmall">-->

<!--    <div treoVerticalNavigationContentHeader >-->

<!--        &lt;!&ndash; Actions &ndash;&gt;-->
<!--        <div class="actions">-->

<!--            &lt;!&ndash; Logo &ndash;&gt;-->
<!--            <div class="logo">-->
<!--                <img src="assets/images/logo/logo.svg">-->
<!--            </div>-->

<!--            &lt;!&ndash; Spacer &ndash;&gt;-->
<!--            <div class="spacer"></div>-->

<!--            &lt;!&ndash; Notifications &ndash;&gt;-->
<!--&lt;!&ndash;            <notifications [notifications]="data.notifications"></notifications>&ndash;&gt;-->

<!--            &lt;!&ndash; User &ndash;&gt;-->
<!--            <user [user]="data.user"-->
<!--                  [showAvatar]="false"></user>-->
<!--        </div>-->

<!--        &lt;!&ndash; User &ndash;&gt;-->
<!--        <div class="user">-->
<!--            <div class="avatar">-->
<!--                <img *ngIf="data.user.avatar"-->
<!--                     [src]="data.user.avatar">-->
<!--                <mat-icon *ngIf="!data.user.avatar"-->
<!--                          [svgIcon]="'account_circle'"></mat-icon>-->
<!--            </div>-->
<!--            <div class="info">-->
<!--                <div class="name">{{data.user.name}}</div>-->
<!--                <div class="email text-secondary">{{data.user.email}}</div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->

<!--    <div treoVerticalNavigationContentFooter>-->
<!--        <div class="logo">-->
<!--            <img src="assets/images/logo/logo-text-on-dark.svg">-->
<!--        </div>-->
<!--    </div>-->

<!--</treo-vertical-navigation>-->

<!-- Wrapper -->
<div class="wrapper">

    <!-- Header -->
<!--    <div class="header">-->

<!--        &lt;!&ndash; Navigation toggle button &ndash;&gt;-->
<!--&lt;!&ndash;        <button class="navigation-toggle-button"&ndash;&gt;-->
<!--&lt;!&ndash;                mat-icon-button&ndash;&gt;-->
<!--&lt;!&ndash;                (click)="toggleNavigation('mainNavigation')">&ndash;&gt;-->
<!--&lt;!&ndash;            <mat-icon [svgIcon]="'menu'"></mat-icon>&ndash;&gt;-->
<!--&lt;!&ndash;        </button>&ndash;&gt;-->

<!--        &lt;!&ndash; Spacer &ndash;&gt;-->
<!--        <div class="spacer"></div>-->

<!--        &lt;!&ndash; Search &ndash;&gt;-->
<!--        <search [appearance]="'bar'"></search>-->

<!--        &lt;!&ndash; Shortcuts &ndash;&gt;-->
<!--        <shortcuts [shortcuts]="data.shortcuts"></shortcuts>-->

<!--        &lt;!&ndash; Messages &ndash;&gt;-->
<!--        <messages [messages]="data.messages"></messages>-->

<!--    </div>-->

    <!-- Content -->
    <div class="content">

        <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
             it won't register the changes on the layout and won't update the view. -->
        <router-outlet *ngIf="true"></router-outlet>

    </div>

</div>
